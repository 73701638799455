<template>
  <window-content v-if="!preventBind" class="financeiro-window doc-editor-v2">
    <div class="w-m-header">
      <div>
        <h2 v-if="documento.id">{{ documento.name }} <small>[{{documento.id}}]</small> <small>[{{documento.code}}]</small></h2>
        <h2 v-else>Novo Documento</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do documento</legend>
          </e-col>
          <e-col class="m-l-sm m-r flex items-center justify-end" style="max-width: 220px; min-height: 32px">
            <erp-s-field label="Receber Atualizações" icon-help="Se ativado, sempre que houver melhorias ou correções neste documento pelo desenvolvedor, o mesmo será atualizado.">
              <sl-checkbox v-model="documento.receberAtualizacoes" />
            </erp-s-field>
          </e-col>
          <e-col class="m-l-sm" style="max-width: 100px">
            <erp-s-field label="Público">
              <sl-checkbox v-model="documento.public" />
            </erp-s-field>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 100px; min-height: 32px">
            <erp-s-field :label="!documento.active ? 'Desativado' : 'Ativo'">
              <sl-checkbox no-yes-no-val v-model="documento.active" id="active"/>
            </erp-s-field>
          </e-col>
          <!--          <e-col class="flex items-center justify-end" style="max-width: 120px; min-height: 32px">
                      <erp-s-field view="ll" label="Público"
                                   icon-help="Marcar como público libera o acesso ao projeto para qualquer pessoa">
                        <sl-checkbox no-yes-no-val v-model="documento.public" id="active"/>
                      </erp-s-field>
                    </e-col>-->
        </e-row>
        <e-row>
          <e-col>
            <e-row mr>
              <e-col>
                <erp-s-field required label="Nome">
                  <erp-input placeholder="Defina um nome para o documento" v-model="documento.name" required autofocus/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 400px">
                <erp-s-field label="Código">
                  <erp-input placeholder="Defina um código único para o documento" v-model="documento.code"/>
                </erp-s-field>
              </e-col>
              <e-col style="max-width: 100px">
                <erp-s-field label="Ordem">
                  <erp-input placeholder="Posição do documento" v-model="documento.order"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Assunto">
                  <erp-input type="text" v-model="documento.subject"/>
                </erp-s-field>
              </e-col>
              <e-col>
                <erp-s-field label="Tipo do documento">
                  <categoria-select ref="categoria" v-model="documento.categoria"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição">
                  <textarea placeholder="Descrição sobre o formulário" v-model="documento.description" class="sl-textarea"
                            rows="3"/>
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field view="tl" label="Anexo" icon-help="Utilize Word para subistituir o template HTML por um Word. Utilize outro formado para substituir o template pelo download do documento anexo.">
                  <input ref="anexo" type="file" accept="*" multiple />
                  <span v-if="documento.url" @click="download" class="fileNameBtn m-l"><file-extension-icon class="m-r-sm" :extension="getExtension(documento.originalFilename)" /> {{documento.originalFilename}}</span>
                  <a v-if="documento.url" @click="removeAnexo" class="m-l text-negative font-12 font-bold">Remover anexo</a>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Conteúdo do Documento</legend>
          </e-col>
        </e-row>
        <e-row>
          <e-col>
            <div class="editor-div">
              <div class="editor-preview">
                <div v-if="!loadingPreview" class="ep-container">
                  <div class="preview-title text-center">Pré-visualização</div>
                  <s-l-editor
                      ref="editor"
                      v-if="menuActive === 'preview' || !documento.id"
                      v-model="documento.documentPreview"
                      placeholder="Monte seu documento aqui..."
                      readonly
                      only-source
                      :show-toolbar="false"
                      :close-click-outside="false"
                  />
                </div>
                <div v-else class="ep-container text-center flex flex-center justify-center center">
                  <div class="font-12">CARREGANDO PRÉ-VISUALIZAÇÃO</div>
                  <div>
                    <u-progress :percentage="loadingPreviewProgress" height="4px" style="width: 100px"/>
                  </div>
                </div>
              </div>
              <div class="editor-source">
                <div class="preview-title text-center">Código Fonte</div>
                <!--              <div class="text-center">Código Fonte</div>-->
                <!--              <textarea @change="preview" @keyup="previewTimeout" class="source-code" v-model="documento.document"></textarea>-->
                <code-editor v-model="documento.document" :languages="[['html']]" class="source-code" width="100%" height="100%" font_size="14px" :wrap_code="true" :spellcheck="false"></code-editor>
              </div>
            </div>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Configurações Avançadas</legend>
          </e-col>
        </e-row>
        <e-row mr class="m-t-sm">
          <e-col>
            <erp-s-field label="Bind Condicional (Avançado)" icon-help="Somente use se tiver conhecimento sobre as configurações.">
              <erp-input placeholder="Somente use se tiver conhecimento sobre as configurações" v-model="documento.config.bind"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr class="m-t-sm">
          <e-col>
            <erp-s-field label="Definir documento exclusivo para entidades do sistema" icon-help="Caso selecionado, ajudará o sistema a entender qual a finalidade deste documento.">
              <label v-for="(e, ekey) in entities" :key="'e-' + ekey" style="display: inline-flex" class="items-center no-select m-r">
                <u-checkbox :val="e.value" v-model="documento.config.entities" />
                <span class="m-l-xs">{{ e.label }}</span>
              </label>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr class="m-t-sm">
          <e-col>
            <erp-s-field label="Restringir emissão do documento para formatos específicos" icon-help="Caso selecionado, ajudará o sistema a filtrar o tipo de emissão dentro do sistema.">
              <label v-for="(e, ekey) in formatos" :key="'f-' + ekey" style="display: inline-flex" class="items-center no-select m-r">
                <u-checkbox :val="e.value" v-model="documento.config.formatos" />
                <span class="m-l-xs">{{ e.label }}</span>
              </label>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="saving" type="button" @click="save('andClose')" label="Salvar Formulário" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
        <!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
                  <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                             self="top right" :offset="[0, 0]">
                    <div>
                      <ul>
                        <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                        <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
                      </ul>
                    </div>
                  </u-popover>
                </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {debounce, UTooltip, UProgress, UCheckbox} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import SlCheckbox from "@/reuse/input/Checkbox"
import CodeEditor from "simple-code-editor"
import mock from "@/domain/documento/helpers/mockDocumento"
import SLEditor from "@/components/layout/components/Editor"
import CategoriaSelect from "@/components/documentos/components/include/CategoriaSelect"
import {
  deleteAnexoDocumento,
  downloadAnexoDocumento,
  findDocumento,
  newDocumento,
  previewDocumento,
  updateDocumento
} from "@/domain/documento/services"
import FileExtensionIcon from "components/sltarefas/components/tarefa/attachments/fileExtensionIcon";
import {finalidades, formatos} from "@/domain/documento/helpers/helpers"

export default {
  name: 'DocumentoWindow',
  provide: function () {
    return {
      container: this
    }
  },
  mixins: [],
  directives: {},
  components: {
    FileExtensionIcon,
    CategoriaSelect,
    SLEditor,
    // ErpSelect,
    // PersonSelect,
    SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent,
    CodeEditor,
    UProgress,
    UCheckbox
  },
  props: ['id', 'options', 'router'],
  data() {
    return {
      loading: false,
      editing: false,
      success: false,
      saving: false,
      status: '',
      preventBind: true,
      loadingPreview: false,
      loadingPreviewProgress: 0,
      showVars: false,
      textEditing: false,
      menuActive: 'preview',
      editorModel: null,
      entities: finalidades,
      formatos: formatos,
      documento: JSON.parse(JSON.stringify(mock))
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.documento.config = JSON.parse(JSON.stringify(this.documento.config))
      this.preventBind = false
    }
  },
  watch: {
    'documento.document' (v) {
      this.$nextTick(() => {
        this.previewTimeout()
      })
    }
  },
  computed: {},
  methods: {
    load() {
      this.loading = true
      findDocumento(this.id || this.documento.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.documento.id = response.data.id
            }
            this.preventBind = true
            this.documento = Object.assign({}, this.documento, this.prepareToEdit(response.data))
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.documento)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.documento))
      data.config = JSON.stringify(data.config)

      return data
    },
    prepareToEdit(data) {
      if (!data.config) {
        data.config = JSON.parse(JSON.stringify(mock.config))
      }
      if (!Array.isArray(data.config.entities) || !data.config.entities) {
        data.config.entities = JSON.parse(JSON.stringify(mock.config.entities))
      }
      if (!Array.isArray(data.config.formatos) || !data.config.formatos) {
        data.config.formatos = JSON.parse(JSON.stringify(mock.config.formatos))
      }
      return data
    },
    preview () {
      if (this.documento && this.documento.document) {
        this.loadingPreview = true
        this.loadingPreviewProgress = 0
        previewDocumento(this.documento.id || null, this.documento.document)
            .then(response => {
              this.loadingPreviewProgress = 100
              this.documento.documentPreview = response.data.documentPreview
              setTimeout(() => {
                this.loadingPreview = false
              }, 200)
            })
            .catch(error => {
              this.loadingPreview = false
              this.alertApiError(error)
            })
      }
    },
    previewTimeout: debounce(function () {
      this.preview()
    }, 2000),
    insertVar(v) {
      this.$refs.editor.addTextToCursor('{{' + v.name + '}}')
    },
    openVars() {
      this.$refs.vars.toggle()
      this.$nextTick(() => {
        setTimeout(() => {
          this.showVars = this.$refs.vars.showed
        }, 120)
      })
    },
    async fileToBase64 (file) {
      return new Promise(resolve => {
        const reader = new FileReader()
        reader.onload = (event) => resolve(event.target.result)
        reader.onerror = error => reject(error)
        reader.readAsDataURL(file)
      })
    },
    async save() {
      let oldMenuActive = null
      if (this.menuActive !== 'edit') {
        oldMenuActive = this.menuActive
      }
      this.menuActive = 'edit'
      this.$nextTick(async () => {
        const data = this.prepareToSave()
        data.creteNewFolder = this.$refs.categoria.getNewData()
        data.document = this.documento.document
        console.log(data.document)
        const relacoes = ['categoria']
        relacoes.map(key => {
          if (data[key] && data[key].id) {
            data[key] = data[key].id
          }
        })
        this.saving = true
        let method = newDocumento
        if (data.id) {
          method = updateDocumento
        }

        if (this.$refs.anexo && this.$refs.anexo.files && this.$refs.anexo.files.length) {
          const file = this.$refs.anexo.files.item(0)
          if (file) {
            data.data = await this.fileToBase64(file)
            data.filename = file.name
          }
        }
        method(data)
            .then(response => {
              this.saving = false
              if (oldMenuActive) {
                this.menuActive = oldMenuActive
              }
              this.documento.id = response.data.id
              this.$nextTick(() => {
                this.dg()
                this.load()
              })
              console.log(response)
            })
            .catch(error => {
              this.saving = false
              this.alertApiError(error)
            })
      })
    },
    getExtension(name) {
      try {
        name = name.split('.')
        return name[name.length - 1]
      } catch (e) {
        return ''
      }
    },
    download () {
      downloadAnexoDocumento(this.documento.id)
          .then(response => {
            console.log(response.data)
            const link = document.createElement('a')
            link.href = response.data.url
            document.body.appendChild(link)
            link.click();
            window.setTimeout(() => {
              document.body.removeChild(link)
            }, 100)
            // window.open(response.data.url)
          })
          .catch(error => {
            this.alertApiError(error)
          })
    },
    removeAnexo () {
      this.$uloc.dialog({
        title: 'Remover arquivo anexo do documento',
        message: 'Você tem certeza que deseja remover o arquivo anexo deste documento?',
        ok: 'Sim',
        cancel: 'Não'
      }).then(() => {
        this.$uloc.loading.show()
        deleteAnexoDocumento(this.documento.id)
            .then(() => {
              this.$uloc.loading.hide()
              this.dg()
              this.load()
            })
            .catch(e => {
              this.$uloc.loading.hide()
              this.alertApiError(e)
            })
      }).catch(() => {})
    }
  }
}
</script>
